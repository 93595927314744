<template>
  <div>
    <h5 class="text-secondary">
      {{ $t("page.condominium.editUser") }} -
      <b-badge
        class="text-white font-weight-normal"
        :variant="userStatusVariant[currentUser.status]"
      >
        {{ $t(currentUser.status) }}
      </b-badge>
    </h5>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <b-form>
        <b-row class="no-gutters ml-n3">
          <b-col class="col-12 col-md-6 pl-3">
            <form-input
              v-model="user.name"
              label="requiredField.name"
              veeName="name"
              :veeRules="{ required: true }"
            />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <form-input
              v-model="user.surname"
              label="requiredField.surname"
              veeName="surname"
              :veeRules="{ required: true }"
            />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <b-row no-gutters align-v="center" class="mb-2">
              <label class="font-bold mb-0"
                ><b>{{ $t("email") }}</b></label
              >
              <b-icon
                v-b-tooltip.hover
                :title="$t(user.emailPublic ? 'publicInfo' : 'privateInfo')"
                class="ml-4"
                :icon="user.emailPublic ? 'eye' : 'eye-slash'"
              ></b-icon>
            </b-row>
            <form-input v-model="user.email" :disabled="true" />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <b-row no-gutters align-v="center" class="mb-2">
              <label class="font-bold mb-0"
                ><b>{{ $t("telephone") }}</b></label
              >
              <b-icon
                v-b-tooltip.hover
                :title="$t(user.telephonePublic ? 'publicInfo' : 'privateInfo')"
                class="ml-4"
                :icon="user.telephonePublic ? 'eye' : 'eye-slash'"
              ></b-icon>
            </b-row>
            <form-input v-model="user.telephone" />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <label class="font-bold"><b>LinkedIn</b></label>
            <div
              v-if="user.linkedIn"
              style="max-width: 95%; overflow-x: hidden; text-overflow: ellipsis"
            >
              <a target="_blank" :href="user.linkedIn">{{ user.linkedIn }}</a>
            </div>
            <div v-else>{{ $t("noInfo") }}</div>
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <form-text-area readonly v-model="user.bio" label="Bio" />
          </b-col>
          <div class="w-100 ml-3 my-4" />
          <b-col class="col-12 col-md-6 pl-3">
            <form-input v-model="user.address" label="address" />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <form-input v-model="user.city" label="city" />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <form-input v-model="user.province" label="province" />
          </b-col>
          <b-col class="col-12 col-md-6 pl-3">
            <form-input v-model="user.zip" label="cap" />
          </b-col>
        </b-row>
      </b-form>
      <hr class="my-3 mx-n3" />
      <b-row class="no-gutters justify-content-end">
        <b-button variant="outline-dark" class="mr-1" @click="$router.go(-1)">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button
          class="text-white"
          variant="primary"
          :disabled="invalid"
          @click="updateUser"
        >
          {{ $t("button.saveChanges") }}
        </b-button>
      </b-row>
    </validation-observer>
    <condominium-table
      class="pt-4"
      :condominiums="condominiums"
      :fields="defaultFields"
      :sortBy="sortByDetails"
      :sortDesc="sortDescDetails"
    />
  </div>
</template>

<script>
import FormInput from "../Base/BaseFormInput";
import FormTextArea from "../Base/BaseFormTextarea";
import CondominiumTable from "../Condominium/CondominiumTable";
export default {
  components: {
    FormInput,
    FormTextArea,
    CondominiumTable
  },
  mounted() {
    this.initForm();
  },
  data() {
    return {
      user: {},
      defaultFields: [
        { key: "name", label: "name", sort: true },
        { key: "address", label: "address", sort: true },
        { key: "role", label: "roleInCondominium", sort: false }
      ],
      userStatusVariant: {
        active: "green",
        notverified: "cyan",
        invited: "yellow"
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["consumer/getCurrentConsumerUser"];
    },
    condominiums() {
      return this.currentUser.condominiums.map(condominium => {
        return {
          id: condominium.condominiumId._id,
          role: condominium.role,
          name: condominium.condominiumId.name,
          address: this.formatAddress(condominium.condominiumId)
        };
      });
    },
    sortByDetails: {
      get() {
        return this.$store.getters["condominium/getSortByDetails"];
      },
      set(value) {
        this.$store.commit("condominium/SET_SORT_BY_DETAILS", value);
      }
    },
    sortDescDetails: {
      get() {
        return this.$store.getters["condominium/getSortDescDetails"];
      },
      set(value) {
        this.$store.commit("condominium/SET_SORT_DESC_DETAILS", value);
      }
    }
  },
  methods: {
    formatAddress(condominium) {
      return [
        condominium.address,
        condominium.streetNumber,
        condominium.city,
        condominium.province
      ].join(", ");
    },
    initForm() {
      this.user = {
        _id: this.currentUser._id,
        name: "",
        surname: "",
        email: this.currentUser.email,
        telephone: "",
        address: "",
        city: "",
        province: "",
        zip: ""
      };
      if (this.currentUser.profile) {
        this.user.name = this.currentUser.profile.name || "";
        this.user.surname = this.currentUser.profile.surname || "";
        this.user.telephone = this.currentUser.profile.telephone || "";
        this.user.address = this.currentUser.profile.address || "";
        this.user.city = this.currentUser.profile.city || "";
        this.user.province = this.currentUser.profile.province || "";
        this.user.zip = this.currentUser.profile.zip || "";
        this.user.bio = this.currentUser.profile.bio || "";
        this.user.linkedIn = this.currentUser.profile.linkedIn || "";
        this.user.emailPublic = this.currentUser.profile.emailPublic || false;
        this.user.telephonePublic =
          this.currentUser.profile.telephonePublic || false;
      }
    },
    async updateUser() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("user/updateUser", {
          userId: this.user._id,
          userInfo: this.getProfilePayload()
        });
        this.$router.push({ name: "ConsumerUsers" });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    getProfilePayload() {
      return {
        name: this.user.name,
        surname: this.user.surname,
        email: this.user.email,
        telephone: this.user.telephone,
        address: this.user.address,
        city: this.user.city,
        province: this.user.province,
        zip: this.user.zip
      };
    }
  }
};
</script>

<style lang="scss" scoped></style>
